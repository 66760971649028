//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';

export default {
  name: 'SButton',
  props: {
    /**
     * Native button disabled attribute
     */
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    colors: {
      type: Object,
      default: () => {}
    },
    text: {
      type: String,
      deafult: 'Button'
    },

    /*
     * Show only button border
     */
    outlined: {
      type: Boolean,
      default: false
    },

    /*
     * Show shadow
     */
    shadow: {
      type: Boolean,
      default: false
    },
    centered: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: 'normal'
    },
    onClick: {
      type: String,
      required: false
    },
    fullWidthMobile: {
      type: Boolean,
      default: false
    },

    /**
     * Link for "a" tag, when empty it is button.
     */
    link: {
      type: [String, Object],
      default: null
    },
    id: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const isTag = computed(() => props.onClick?.includes('ga'));
    const gaTag = computed(() => (process.client && window?.ga ? props.onClick : null));

    const containerCssVars = computed(() => {
      const cssVars = {};

      if (props.colors && String(props.colors?.textColor).length) {
        cssVars['--button-text-color'] = props.colors.textColor;
      }

      if (props.colors && String(props.colors?.backgroundColor).length) {
        cssVars['--button-background-color'] = props.colors.backgroundColor;
      }

      return cssVars;
    });

    const tag = computed(() => {
      if (props.link === null) {
        return 'button';
      }

      switch (typeof props.link) {
        case 'object':
          return 'router-link';
        case 'string':
          return 'a';
      }

      return 'button';
    });

    return {
      tag,
      containerCssVars,
      gaTag,
      isTag
    };
  }
};
