var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._g(_vm._b({directives:[{name:"focus",rawName:"v-focus"}],tag:"component",staticClass:"n-button ff-bold",class:{
    'n-button--outline': _vm.outlined,
    'n-button--disabled': _vm.disabled,
    'n-button--shadow': _vm.shadow,
    'n-button--loading': _vm.loading,
    'n-button--centered': _vm.centered,
    'n-button--full-width': _vm.fullWidth,
    'n-button--full-width-mobile': _vm.fullWidthMobile,
    'n-button--size-small': _vm.size === 'small',
    'n-button--size-large': _vm.size === 'large',
    'n-button--size-xlarge': _vm.size === 'xlarge',
    'n-button--primary': _vm.color === 'primary',
    'n-button--white': _vm.color === 'white',
    'n-button--gray': _vm.color === 'gray',
    'n-button--custom-bg': Object.keys(_vm.containerCssVars).includes('--button-background-color'),
    'n-button--custom-cl': Object.keys(_vm.containerCssVars).includes('--button-text-color')
  },style:(_vm.containerCssVars),attrs:{"id":_vm.id,"disabled":_vm.disabled,"link":_vm.link,"onclick":_vm.isTag ? _vm.gaTag : _vm.onClick}},'component',_vm.$attrs,false),_vm.$listeners),[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.text))]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }